import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClientModule } from '../../node_modules/@angular/common/http';
import { RouterModule, NoPreloading, Routes } from '@angular/router';

import { AuthInterceptor } from '../services/auth.interceptor';
import { AdalGuard, AdalService } from 'adal-angular4';
import { AppRoutingModule } from './app-routing.module';
import { AppInsightsService } from '../services/app-insights.service';
import { IdentityService } from '../services/identity.service';
import { LegalAdGuard } from '../guards/legal-ad.guard';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { LoadingIndicatorInterceptor } from '../services/loading-indicator.interceptor';
import { LegalAdService } from '../services/legal-ad.service';
import { DataHandlerService } from '../services/data-handler.service';
 
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormAssistanceComponent } from './form-assistance/form-assistance.component';
import { LegalAdFormComponent } from './legal-ad-form/legal-ad-form.component';
import { NavTopComponent } from './nav-top/nav-top.component';

import { OptionNotNegDirective } from '../directives/OptionNotNegDirective';
import { FocusFirstInvalidFieldDirective } from '../directives/FocusFirstInvalidFieldDirective';

import { FormatRequestStatusPipe } from '../pipes/FormatRequestStatusPipe';
import { FormRequestApprovalsPipe } from '../pipes/FormRequestApprovalsPipe';
import { FormRequestSubmittedPipe } from '../pipes/FormRequestSubmittedPipe';
import { FilterCompletedApprovalsPipe } from '../pipes/FilterCompletedApprovalsPipe';
import { SortByDateApprovedPipe } from '../pipes/SortByDateApprovedPipe';

const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'form', component: LegalAdFormComponent, canActivate: [LegalAdGuard] },
  { path: 'form/:id', component: LegalAdFormComponent, canActivate: [LegalAdGuard] }
  /*{ path: 'token', component: TokenComponent, canActivate: [AdalGuard] }*/
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormAssistanceComponent,
    LegalAdFormComponent,
    NavTopComponent,
    OptionNotNegDirective,
    FocusFirstInvalidFieldDirective,
    FormatRequestStatusPipe,
    FormRequestApprovalsPipe,
    FormRequestSubmittedPipe,
    FilterCompletedApprovalsPipe,
    SortByDateApprovedPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MultiSelectModule,
    CalendarModule,
    FileUploadModule,
    ToastModule,
    TableModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled' })
  ],
  providers: [        
    AdalService,
    AdalGuard,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    AppInsightsService,
    IdentityService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    LoadingIndicatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingIndicatorInterceptor,
      multi: true
    },    
    LegalAdService,
    DataHandlerService,
    [MessageService]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
