import { LegalAdFile } from "./LegalAdFile";
import { GeographicArea } from './GeographicArea';
import { MediaType } from './MediaType';
import { OfferType } from './OfferType';
import { ImageType } from './ImageType';
import { HomeType } from './HomeType';
import { Area } from "./Area";
import { Brand } from "./Brand";
import { Division } from "./Division";
import { RequestType } from "./RequestType";
import { TargetType } from "./TargetType";

export class LegalAd {
    public legalAdId: number;
    public createdDate: Date;
    public modifiedDate: Date;

    public formRequestType: string;
    public dateSubmitted: Date;
    public dateApproved: Date;
    public requestTitle: string;
    public requestStatus: FormRequestStatus;
    public requestor: string;
    public requestorUserId: string;

    public legalAdFiles: LegalAdFile[] = [];
    public geographicAreas: GeographicArea[] = [];
    public mediaTypes: MediaType[] = [];
    public offerTypes: OfferType[] = [];
    public homeTypes: HomeType[] = [];
    public imageTypes: ImageType[] = [];

    public areaId: number;
    public marketId: number;
    public brandId: number;
    public requestTypeId: number;
    public targetTypeId: number;

    public dateFeedbackNeeded: Date;
    public versionedText: string;
    public isOffer: boolean;
    public pulteFinancing: boolean;
    public offerDetails: string;
    public isOfferLimited: boolean;
    public offerLimits: string;
    public isContingentOptions: boolean;
    public avgHomePrice: number;
    public avgOptionsAvailable: number;
    public avgOptionsSelected: number;
    public isLotPremiumsExcluded: boolean;
    public isCombinableOffer: boolean;
    public offerAdditionalLimits: string;
    public isOfferWidelyAvailable: boolean;
    public imageTypesDescription: string;
    public contractExecStartDate: Date;
    public contractExecEndDate: Date;
    public contractCloseByDate: Date;
    public wasCommDeveloped: boolean;
    public isCommAmenities: boolean;
    public commProdAdvertised: string;
    public isHoaDues: boolean;
    public isAgeRestricted: boolean;
    public isApprovedByPMC : boolean;
    public additionalInfo: string;
    public approverNotes: string;
}

export enum FormRequestStatus {
    Created = "Created",
    Submitted = "Submitted",
    Approved = "Approved",
    ApprovedNoChanges = "ApprovedNoChanges",
    ApprovedWithChanges = "ApprovedWithChanges"
}