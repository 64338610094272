import { Pipe, PipeTransform } from '@angular/core';
import { LegalAd, FormRequestStatus } from '../models/LegalAd';

@Pipe({
    name: 'sortByDateApproved'
  })
  export class SortByDateApprovedPipe implements PipeTransform {
  
    transform(items: LegalAd[], filterArg) {
      if (items) {
        const sorted = items.sort((x, y) => {
          return this.getTime(y.dateApproved) - this.getTime(x.dateApproved); // Start sort by ascending date
        });
        return sorted.slice(0);
      }
      else {
        return items;
      }
    }

    private getTime(date?: Date) {
        return date != null ? new Date(date).getTime() : 0;
    }
  }