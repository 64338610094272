import { Pipe, PipeTransform } from '@angular/core';
import { LegalAd } from '../models/LegalAd';
import { Constants } from 'src/app/Constants';

@Pipe({
    name: 'filterCompletedApprovalsPipe',
    pure: false
})
export class FilterCompletedApprovalsPipe implements PipeTransform {
    transform(items: LegalAd[], approver: boolean): any {
        if (!items || !approver)
            return items;
        return items.filter(item => (this.dateDiffDays(new Date(), item.dateApproved) < Constants.NumberOfDaysDisplayFormAfterApprove));
    }

    private dateDiffDays = function(date1, date2) {
        var dt1 = new Date(date1);
        var dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24));
    }
}