import { LegalAdFile } from "./LegalAdFile";
import { GeographicArea } from './GeographicArea';
import { MediaType } from './MediaType';
import { OfferType } from './OfferType';
import { ImageType } from './ImageType';
import { HomeType } from './HomeType';
import { Area } from "./Area";
import { Brand } from "./Brand";
import { Division } from "./Division";
import { RequestType } from "./RequestType";
import { TargetType } from "./TargetType";

// THIS IS A FORMATTED TYPE OF THE 'LEGALAD' MODEL THAT HAS NO COMPLEX TYPES
// AND MAKES THE CONTAINED DATA MORE HUMAN-READABLE
export class FormattedLegalAd {
    public "Legal Ad ID": number;

    public "Form Request Type": string;
    public "Date Submitted": string;
    public "Date Approved": string;
    public "Title": string;
    public "Status": string;
    public "Requestor": string;
    public "Requestor User ID": string;

    public "Files": string;
    public "Geographic Areas": string;
    public "Media Types": string;
    public "Offer Types": string;
    public "Home Types": string;
    public "Image Types": string;

    public "Area": string;
    public "Division": string;
    public "Brand": string;
    public "Request Type": string;
    public "Target Type": string;

    public "Date Feedback Needed": string;
    public "Versioned Text": string;
    public "Is Offer": boolean;
    public "Pulte Financing": boolean;
    public "Offer Details": string;
    public "Is Offer Limited": boolean;
    public "Offer Limits": string;
    public "Is Contingent Options": boolean;
    public "Average Home Price": string;
    public "Average Options Available": string;
    public "Average Options Selected": string;
    public "Are Lot Premiums Excluded": boolean;
    public "Is Combinable Offer": boolean;
    public "Offer Additional Limits": string;
    public "Is Offer Widely Available": boolean;
    public "Image Types Description": string;
    public "Contract Start Date": string;
    public "Contract End Date": string;
    public "Contract Close-By Date": string;
    public "Was Community Developed": boolean;
    public "Is Community Amenities": boolean;
    public "Community Product Advertised": string;
    public "Has HOA Dues": boolean;
    public "Is Age Restricted": boolean;
    public "Is Approved By PMC": boolean;
    public "Additional Information": string;
    public "Approver Notes": string;

    public "Form Link": string;
}