export const environment = {
    appUrl: 'https://legalad.staging.pulte.com/',
    apiUrl: 'https://legaladapi.staging.pulte.com/odata/',
    applicationInsights: {
        instrumentationKey: '4a27c7a6-03bd-44e0-b3b6-c8eab6f6b94b'
    },
    azureAuthProvider: {
        clientId: 'bb1361cd-3f18-45b5-b6a0-a7b8934cee60',
        tenant: '1a9277a3-ef66-41f6-96b5-c5390ee468a7'
    },
    production: false,
    envName: 'stg'
};
