import { Pipe, PipeTransform } from '@angular/core';
import { LegalAd, FormRequestStatus } from '../models/LegalAd';

@Pipe({
    name: 'formatRequestStatus'
  })
  export class FormatRequestStatusPipe implements PipeTransform {
  
    transform(requestType: FormRequestStatus) {
      switch(requestType){
        case FormRequestStatus.Created:
          return requestType;
        case FormRequestStatus.Submitted:
          return requestType;
        case FormRequestStatus.Approved:
          return requestType;        
        case FormRequestStatus.ApprovedWithChanges:
          return "Approved With Changes";
        case FormRequestStatus.ApprovedNoChanges:
          return "Approved No Changes";
        default:
          return requestType;
      }
    }
  }