import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

import { Division } from '../models/Division';
import { LegalAd } from '../models/LegalAd'
import { Brand } from 'src/models/Brand';
import { TargetType } from 'src/models/TargetType';
import { RequestType } from 'src/models/RequestType';

import { Area } from 'src/models/Area';
import { MediaType } from 'src/models/MediaType';
import { OfferType } from 'src/models/OfferType';
import { ImageType } from 'src/models/ImageType';
import { HomeType } from 'src/models/HomeType';
import { LegalAdFile } from 'src/models/LegalAdFile';
import { LegalAdReviewForm } from 'src/models/LegalAdReviewForm';
import { GeographicArea } from 'src/models/GeographicArea';

import { AdalService } from 'adal-angular4';
import { LoadingIndicatorService } from './loading-indicator.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/Constants';

import { Cacheable } from '../../node_modules/ngx-cacheable';

@Injectable()
export class LegalAdService {

  private sub: any;

  constructor(public http: HttpClient, private messageService: MessageService, private adalService: AdalService,
              private loadingService: LoadingIndicatorService, private router: Router) { }

  getForms() {
    const opts = this.getOpts();
    return this.http.get<LegalAd[]>(environment.apiUrl + 'legalad' + '?$expand=LegalAdFiles', opts);
  };

  getFormByID(id: number) {
    const opts = this.getOpts();
    return this.http.get<LegalAd>(environment.apiUrl + 'legalad/' + id + '?$expand=LegalAdFiles', opts)
  }

  checkFormsModified() {
    const opts = this.getOpts();
    return this.http.get<LegalAd[]>(environment.apiUrl + 'legalad' + '?&$count=true&$select=ModifiedDate&$top=1&$orderby=ModifiedDate desc', opts);
  };

  async createLegalAdForm(legalAdReviewForm: LegalAd) {
    this.messageService.add({severity: 'success', summary: 'Success', detail: "Submitting form..."});
    const opts = this.getOpts();
    return await this.http.post<LegalAd>(environment.apiUrl + 'legalad', JSON.stringify(legalAdReviewForm), opts).toPromise()
    .catch(err => {
      console.log(err);
    });
  }

  async updateLegalAdForm(legalAdReviewForm: LegalAd) {
    if (legalAdReviewForm && legalAdReviewForm.legalAdId){
      this.messageService.add({severity: 'success', summary: 'Success', detail: "Updating form..."});
      const opts = this.getOpts();
      return await this.http.put<LegalAd>(environment.apiUrl + 'legalad/' + legalAdReviewForm.legalAdId, JSON.stringify(legalAdReviewForm), opts).toPromise()
      .catch(err => {
        console.log(err);
      });
    }
  }

  async uploadAdhocFiles(file: File, guid: string) : Promise<any> {
    const opts = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*.*'
      })
    };
    const input = new FormData();
    input.append('document', file, file.name);
    return await this.http.post(environment.apiUrl + 'legaladfile?guid=' + guid, input, opts).toPromise()
      .catch(err => {
         console.log(err.message);
         console.log(file.name);
         return this.http.post(environment.apiUrl + 'legaladfile?guid=' + guid, input, opts).toPromise();
        });
  }

  getAdhocFiles(guid: string){
    const opts = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*.*'
      })
    };
    return this.http.get(environment.apiUrl + 'legaladfile?guid=' + guid, opts);
  }

  async deleteAdhocFiles(fileName: string){
    const opts = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*.*'
      })
    };
    return await this.http.delete(environment.apiUrl + 'legaladfile?fileName=' + fileName, opts).toPromise()
      .catch(err => { console.log(err); });
  }

  // Single-Select 
  @Cacheable()              
  getFinancialAreas() {
    return this.http.get<Area[]>(environment.apiUrl + 'financialarea');
  }

  @Cacheable()              
  getFinancialDivisions(areaId?: number) {
    if (!areaId || areaId < 0)
      return this.http.get<Division[]>(environment.apiUrl + 'financialdivision');
    else
      return this.http.get<Division[]>(environment.apiUrl + 'financialdivision?areaId=' + areaId);
  }

  @Cacheable()              
  getFinancialBrands() {
    return this.http.get<Brand[]>(environment.apiUrl + 'financialbrand');
  }

  @Cacheable()              
  getTargetTypes() {
    return this.http.get<TargetType[]>(environment.apiUrl + 'targettype');
  }

  @Cacheable()              
  getRequestTypes() {
    return this.http.get<RequestType[]>(environment.apiUrl + 'requesttype');
  }

  // Multi-Select
  @Cacheable()              
  getMediaTypes() {
    return this.http.get<MediaType[]>(environment.apiUrl + 'mediatype');
  }

  @Cacheable()              
  getOfferTypes() {
    return this.http.get<OfferType[]>(environment.apiUrl + 'offertype');
  }

  @Cacheable()              
  getImageTypes() {
    return this.http.get<ImageType[]>(environment.apiUrl + 'imageType');
  }

  @Cacheable()              
  getHomeTypes() {
    return this.http.get<HomeType[]>(environment.apiUrl + 'hometype');
  }

  getGeographicAreas() : GeographicArea[]{
    // STATIC DATA
    return [{ geoAreaId: 1, name: 'National' }, { geoAreaId: 2, name: 'AK' }, { geoAreaId: 3, name: 'AL' }, 
      { geoAreaId: 4, name: 'AR' }, { geoAreaId: 5, name: 'AZ' }, { geoAreaId: 6, name: 'CA' }, { geoAreaId: 7, name: 'CO' }, 
      { geoAreaId: 8, name: 'CT' }, { geoAreaId: 9, name: 'DE' }, { geoAreaId: 10, name: 'FL' }, { geoAreaId: 11, name: 'GA' }, 
      { geoAreaId: 12, name: 'HI' }, { geoAreaId: 13, name: 'IA' }, { geoAreaId: 14, name: 'ID' }, { geoAreaId: 15, name: 'IL' },
      { geoAreaId: 16, name: 'IN' }, { geoAreaId: 17, name: 'KS' }, { geoAreaId: 18, name: 'KY' }, { geoAreaId: 19, name: 'LA' }, 
      { geoAreaId: 21, name: 'MD' }, { geoAreaId: 22, name: 'ME' }, { geoAreaId: 23, name: 'MI' }, { geoAreaId: 24, name: 'MN' }, 
      { geoAreaId: 25, name: 'MO' }, { geoAreaId: 26, name: 'MS' }, { geoAreaId: 27, name: 'MT' }, { geoAreaId: 28, name: 'NC' }, 
      { geoAreaId: 29, name: 'ND' }, { geoAreaId: 30, name: 'NE' }, { geoAreaId: 31, name: 'NH' }, { geoAreaId: 32, name: 'NJ' }, 
      { geoAreaId: 33, name: 'NM' }, { geoAreaId: 34, name: 'NV' }, { geoAreaId: 35, name: 'NY' }, { geoAreaId: 36, name: 'OH' }, 
      { geoAreaId: 37, name: 'OK' }, { geoAreaId: 38, name: 'OR' }, { geoAreaId: 39, name: 'PA' }, { geoAreaId: 40, name: 'RI' },
      { geoAreaId: 41, name: 'SC' }, { geoAreaId: 42, name: 'SD' }, { geoAreaId: 43, name: 'TN' }, { geoAreaId: 44, name: 'TX' }, 
      { geoAreaId: 45, name: 'UT' }, { geoAreaId: 46, name: 'VA' }, { geoAreaId: 47, name: 'VT' }, { geoAreaId: 48, name: 'WA' }, 
      { geoAreaId: 49, name: 'WI' }, { geoAreaId: 50, name: 'WV' }, { geoAreaId: 51, name: 'WY' }];
  }

  private getOpts() {
    const opts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*.*'
      })
    };
    return opts;
  }
}
