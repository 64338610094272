import { Component, OnInit } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { LegalAd } from '../../models/LegalAd';
import _ from "lodash";

import { AdalService } from 'adal-angular4';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';
import { LegalAdService } from '../../services/legal-ad.service';
import { DataHandlerService } from 'src/services/data-handler.service';
import { MessageService } from 'primeng/api'
import { AppInsightsService } from '../../services/app-insights.service';
import { Division } from '../../models/Division';
import { IdentityService, IdentityServiceRole } from 'src/services/identity.service';
import { RequestorItem } from 'src/models/RequestorItem';
import { LegalAdReviewForm } from 'src/models/LegalAdReviewForm';
import { Constants } from '../Constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  private sub: any;
  private greetingMessage: string;

  private approvalsCollapse: boolean = false;
  private requestsCollapse: boolean = false;
  private loading: boolean = false;

  private forms: any;
  private cachedForms: any;

  private divisionData: Division[];
  private selectedDivisionId: number;

  private requestorItems: RequestorItem[] = [];
  private selectedRequestors: string[] = [];
  private selectedFilterDate: Date;

  // Roles
  private corpsys = false;
  private admin = false;
  private requestor = false;
  private approver = false;

  constructor(private adalService: AdalService, private appInsightsService: AppInsightsService, private legalAdService: LegalAdService, private dataHandlerService: DataHandlerService,
    private loadingService: LoadingIndicatorService, private messageService: MessageService, private router: Router, private identityService: IdentityService) {
    this.appInsightsService.logPageView('home.component', '/home');
    this.sub = loadingService.onLoadingChanged.subscribe(isLoading => this.loading = isLoading);
  }

  ngOnInit() {
    this.adalService.handleWindowCallback();
    this.formatGreetingMessage();
    if (this.adalService.userInfo.authenticated) {
      this.getFormData();
      const role = this.identityService.getRoles();
      switch (role[0]) {
        case IdentityServiceRole.LegalAdApprove:
          this.approver = true;
          break;
        case IdentityServiceRole.LegalAdAdmin:
          this.admin = true;
          break;
        case IdentityServiceRole.LegalAdCorpSystems:
          this.corpsys = true;
          break;
        case IdentityServiceRole.LegalAdRequest:
          this.requestor = true;
          break;
        default:
          break;
      }
    }
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }

  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }

  private getFormData() {
    this.getForms();
    this.getDivisionData(null);
  }

  private getForms() {
    this.forms = null;
    this.selectedDivisionId = -1;
    this.messageService.add({ severity: 'success', summary: 'Success', detail: "Retrieving Legal Ad Forms..." });
    // If our cached # count of forms doesn't match the # we got back OR our cached ModifiedDate value
    // doesn't match the most recent ModifiedDate of the forms (i.e. a form was recently updated or added...),
    // then pull an entirely new set of forms, otherwise use the cached Form data already stored locally.
    this.sub = this.legalAdService.checkFormsModified().subscribe((dates: any) => {
      if (!!dates && dates.length != 0) {
        const count = dates['@odata.count'];
        const cacheCount = localStorage.getItem(Constants.FormsCountCacheKey)
        const latestModifiedDate = new Date((new Date(dates[0].modifiedDate)).setMilliseconds(0)); //clear milliseconds difference
        const cacheModifiedDate = new Date((new Date(localStorage.getItem(Constants.ModifiedDateCacheKey)).setMilliseconds(0))); //clear milliseconds difference
        if (!cacheCount || count != cacheCount || !cacheModifiedDate || latestModifiedDate > cacheModifiedDate) {
          this.sub = this.legalAdService.getForms().subscribe((forms: any) => {
            // Set Forms
            this.forms = forms.sort((a, b) => this.getTime(b.dateSubmitted) - this.getTime(a.dateSubmitted)).sort((a, b) => b.legalAdId - a.legalAdId);
            this.cachedForms = this.forms;
            // Set Cache for Forms, Count and ModifiedDate
            localStorage.setItem(Constants.FormsCacheKey, JSON.stringify(this.forms));
            localStorage.setItem(Constants.FormsCountCacheKey, count);
            localStorage.setItem(Constants.ModifiedDateCacheKey, latestModifiedDate ? latestModifiedDate.toUTCString() : null);
            // Set Requestors
            this.requestorItems = this.getRequestorsFromForms(this.forms);
          }, error => {
            console.log(error.message);
            this.forms = this.cachedForms;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving your Legal Ad Forms..." });
          });
        }
        else {
          // Set Forms
          this.forms = JSON.parse(localStorage.getItem(Constants.FormsCacheKey));
          this.cachedForms = this.forms;
          // Set Requestors
          this.requestorItems = this.getRequestorsFromForms(this.forms);
        }
      }
    }, error => {
      console.log(error.message);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving your Legal Ad Forms..." });
    });
  }

  private getDivisionData(value) {
    this.selectedDivisionId = -1;
    this.sub = this.legalAdService.getFinancialDivisions(value).subscribe((divisions: any) => {
      if (!!divisions && divisions.length > 0) {
        this.divisionData = divisions;
      }
      else { }
    }, error => {
      console.log(error.message);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving the divisions..." });
    });
  }

  private sendSelectedFormToRoute(selectedForm: LegalAd): void {
    this.router.navigate(['/form', selectedForm.legalAdId]);
  }

  private setFilteredFormsList() {
    if (this.cachedForms && this.cachedForms.length > 0) {
      var filteredList = this.cachedForms;

      // Filter by Date, if one was selected.
      if (this.selectedFilterDate) {
        var filterDate = this.selectedFilterDate;
        filteredList = filteredList.filter(function (form) { return form.dateSubmitted && new Date(form.dateSubmitted).setHours(0, 0, 0, 0) == new Date(filterDate).setHours(0, 0, 0, 0) });
      }

      // Filter by Division, if one was selected.
      const selectedDivisionId = this.selectedDivisionId;
      if (this.selectedDivisionId >= 0)
        filteredList = filteredList.filter(function (form) { return form.marketId === selectedDivisionId });

      // Filter by Requestor, if one (or more) was selected.
      if (this.selectedRequestors && this.selectedRequestors.length > 0) {
        var requestorFilteredList = []
        this.selectedRequestors.forEach(requestor => {
          requestorFilteredList = _.union(requestorFilteredList, filteredList.filter(function (form) { return form.requestorUserId === requestor }));
        });
        filteredList = requestorFilteredList;
      }

      this.forms = filteredList;
    }
  }

  private undoFilters() {
    this.selectedDivisionId = -1;
    this.selectedFilterDate = null;
    this.selectedRequestors = [];
    this.forms = this.cachedForms;
  }

  private setRequestsCollapse() {
    this.requestsCollapse = !this.requestsCollapse;
  }

  private setApprovalsCollapse() {
    this.approvalsCollapse = !this.approvalsCollapse;
  }

  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }

  private async exportExcel() {
    if (this.forms && this.forms.length > 0) {
      this.sub = this.dataHandlerService.getAllDataForExport().subscribe((data: any) => {
        this.dataHandlerService.exportDataToExcel(this.forms as LegalAdReviewForm[], data);
      }, error => {
        console.log(error.message);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an getting the Legal Ad Support Data..." });
      });
    }
  }

  private getRequestorsFromForms(forms: LegalAd[]): RequestorItem[] {
    var hash = Object.create(null);
    var requestors = [];
    for (var i = 0; i < this.forms.length; i++) {
      if (!hash[this.forms[i].requestorUserId]) {
        hash[this.forms[i].requestorUserId] = true;
        var requestor = { label: this.forms[i].requestor, value: this.forms[i].requestorUserId };
        requestors.push(requestor);
      }
    }
    return requestors.sort((a, b) => a.label.localeCompare(b.label));
  }

  private scrollTo(className: string): void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  private formatGreetingMessage() {
    var greet = '';
    var name = '';

    var myDate = new Date();
    var hrs = myDate.getHours();

    if (hrs < 12)
      greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
      greet = 'Good Evening';

    if (this.adalService.userInfo.authenticated && this.adalService.userInfo.profile.given_name)
      name = this.adalService.userInfo.profile.given_name + ' ' + this.adalService.userInfo.profile.family_name

    this.greetingMessage = `${greet} ${name}`.trim() + '!';
  }
}
