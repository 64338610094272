import { Pipe, PipeTransform } from '@angular/core';
import { LegalAd, FormRequestStatus } from '../models/LegalAd';

@Pipe({
    name: 'filterApprovals',
    pure: false
})
export class FormRequestApprovalsPipe implements PipeTransform {
    transform(items: LegalAd[], filterApprovals: boolean): any {
        if (!items)
            return items;
        else if (filterApprovals)
            return items.filter(item => (item.requestStatus == FormRequestStatus.Approved           || 
                                         item.requestStatus == FormRequestStatus.ApprovedNoChanges  || 
                                         item.requestStatus == FormRequestStatus.ApprovedWithChanges));
        else
            return items.filter(item => (item.requestStatus != FormRequestStatus.Approved           && 
                                         item.requestStatus != FormRequestStatus.ApprovedNoChanges  && 
                                         item.requestStatus != FormRequestStatus.ApprovedWithChanges));
    }
}