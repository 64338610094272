import { Component, OnInit } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-form-assistance',
  templateUrl: './form-assistance.component.html',
  styleUrls: ['./form-assistance.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(800, style({opacity:1})) 
      ]),
      transition(':leave', [
        animate(800, style({opacity:0})) 
      ])
    ])
  ]
})
export class FormAssistanceComponent implements OnInit {

  title = 'Legal Ad Review Form';

  constructor() { }

  ngOnInit() {
  }
}
