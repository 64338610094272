import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { forkJoin } from 'rxjs';

import { Division } from '../models/Division';
import { LegalAd } from '../models/LegalAd'
import { Brand } from 'src/models/Brand';
import { TargetType } from 'src/models/TargetType';
import { RequestType } from 'src/models/RequestType';

import { Area } from 'src/models/Area';
import { MediaType } from 'src/models/MediaType';
import { ImageType } from 'src/models/ImageType';
import { HomeType } from 'src/models/HomeType';
import { LegalAdFile } from 'src/models/LegalAdFile';
import { LegalAdReviewForm } from 'src/models/LegalAdReviewForm';
import { LegalAdGeoArea } from 'src/models/LegalAdGeoArea';
import { FormattedLegalAd } from 'src/models/FormattedLegalAd';
import { GeographicArea } from 'src/models/GeographicArea';
import { LegalAdService } from './legal-ad.service';

import { AdalService } from 'adal-angular4';
import { LoadingIndicatorService } from './loading-indicator.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { format } from 'url';

@Injectable()
export class DataHandlerService {

  private sub: any;

  private geoAreaData: GeographicArea[];
  private mediaTypeData: MediaType[];
  private imageTypeData: ImageType[];
  private homeTypeData: HomeType[];
  private areaData: Area[];
  private divisionData: Division[];
  private brandData: Brand[];
  private requestTypeData: RequestType[];
  private targetTypeData: TargetType[];

  constructor(public http: HttpClient, private messageService: MessageService, private adalService: AdalService,
              private loadingService: LoadingIndicatorService, private legalAdService: LegalAdService, private router: Router) 
  {
  }

  getIdForMatchingObj(id : number, array : any[]) : number {
    if (!id || id < 0 || !array || array.length <= 0)
      return -1;
    var matchingObj = array.find(a => a.id == id);
    return (matchingObj && matchingObj.id) ? id : -1;     
  }

  getNameForMatchingObj(id : number, array : any[]) : string {
    if (!id || id < 0 || !array || array.length <= 0)
      return null;
    var matchingObj = array.find(a => a.id == id);
    return (matchingObj && matchingObj.name) ? matchingObj.name : null;     
  }

  getRequestTypeIdForMatchingObj(id : number, array : any[]) : number {
    if (!id || id < 0 || !array || array.length <= 0)
      return -1;
    var matchingObj = array.find(a => a.requestTypeId == id);
    return (matchingObj && matchingObj.requestTypeId) ? id : -1;     
  }

  getRequestTypeNameForMatchingObj(id : number, array : any[]) : string {
    if (!id || id < 0 || !array || array.length <= 0)
      return null;
    var matchingObj = array.find(a => a.requestTypeId == id);
    return matchingObj ? matchingObj.type : null;     
  }

  getTargetIdForMatchingObj(id : number, array : any[]) : number {
    if (!id || id < 0 || !array || array.length <= 0)
      return -1;
    var matchingObj = array.find(a => a.targetTypeId == id);
    return (matchingObj && matchingObj.targetTypeId) ? id : -1;     
  }

  getTargetNameForMatchingObj(id : number, array : any[]) : string {
    if (!id || id < 0 || !array || array.length <= 0)
      return null;
    var matchingObj = array.find(a => a.targetTypeId == id);
    return matchingObj ? matchingObj.type : null;     
  }

  getMatchingGeoAreaObjArray(data: GeographicArea[], inputData: LegalAdGeoArea[]) {
    if (inputData)
      return data.filter(o => inputData.find(o2 => o.name.trim() === o2.geographicArea.trim()));
  }

  getMatchingTypeObjArray(data: any[], inputData: any[], propID: string) {
    if (inputData)
      return data.filter(o => inputData.find(o2 => o[propID] === o2[propID]));
  }

  // For Retrieving Necessary LegalAd Collections Data
  getAllDataForExport() {
    return forkJoin (
      this.legalAdService.getFinancialAreas(),
      this.legalAdService.getFinancialDivisions(),
      this.legalAdService.getFinancialBrands(),
      this.legalAdService.getRequestTypes(),
      this.legalAdService.getTargetTypes(),
      this.legalAdService.getMediaTypes(),
      this.legalAdService.getHomeTypes(),
      this.legalAdService.getImageTypes()
      );
  }

  exportDataToExcel(dataToExport: any[], collectionData: any[]) {
    // Set Necessary LegalAd Collections Data.
    this.areaData = collectionData[0];
    this.divisionData = collectionData[1];
    this.brandData = collectionData[2];
    this.requestTypeData = collectionData[3];
    this.targetTypeData = collectionData[4];
    this.mediaTypeData = collectionData[5];
    this.homeTypeData = collectionData[6];
    this.imageTypeData = collectionData[7];
    this.geoAreaData = this.legalAdService.getGeographicAreas();

    // Convert Form Data to a more formatted state.
    let formattedForms = this.createFormattedFormDataForExport(dataToExport);

    // Export.
    import("xlsx").then(xlsx => {
      var wb = xlsx.utils.book_new() // make Workbook of Excel
      // add Worksheet to Workbook   
      var ws = xlsx.utils.json_to_sheet(formattedForms); 
      //worksheet['insertHyperlinks'] = true;
      xlsx.utils.book_append_sheet(wb, ws, 'Legal Ad Forms')
      //const workbook = { Sheets: { 'Forms': worksheet }, SheetNames: ['Forms'] };
      const excelBuffer: any = xlsx.write(wb, { bookType: 'xlsx', type: 'array', });
      this.saveAsExcelFile(excelBuffer, "Legal_Ad_Forms");
    });
  }

  // Parse each form into a more readable object and add to new array.
  createFormattedFormDataForExport(forms: LegalAdReviewForm[]) : FormattedLegalAd[] {
    var currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });
    var formattedLegalAds = [];
    forms.forEach(form => {
      var formattedLegalAd = new FormattedLegalAd();

      // Main Form Information
      formattedLegalAd['Legal Ad ID'] = form.legalAdId;
      formattedLegalAd['Form Request Type'] = form.formRequestType;
      formattedLegalAd['Title'] = form.requestTitle;
      formattedLegalAd['Status'] = form.requestStatus;
      formattedLegalAd['Requestor'] = form.requestor;
      formattedLegalAd['Requestor User ID'] = form.requestorUserId;
      formattedLegalAd['Date Submitted'] = form.dateSubmitted ? new Date(form.dateSubmitted).toLocaleDateString() : null;
      formattedLegalAd['Date Approved'] = form.dateApproved ? new Date(form.dateApproved).toLocaleDateString() : null;

      // Additional Form Information
      formattedLegalAd['Date Feedback Needed'] = form.dateFeedbackNeeded ? new Date(form.dateFeedbackNeeded).toLocaleDateString() : null;
      formattedLegalAd['Versioned Text'] = form.versionedText;
      formattedLegalAd['Is Offer'] = form.isOffer;
      formattedLegalAd['Pulte Financing'] = form.pulteFinancing;
      formattedLegalAd['Offer Details'] = form.offerDetails;
      formattedLegalAd['Offer Limits'] = form.offerLimits;
      formattedLegalAd['Is Offer Limited'] = form.isOfferLimited;
      formattedLegalAd['Is Contingent Options'] = form.isContingentOptions;
      formattedLegalAd['Average Home Price'] = form.avgHomePrice ? currencyFormatter.format(form.avgHomePrice) : null;
      formattedLegalAd['Average Options Available'] = form.avgOptionsAvailable ? currencyFormatter.format(form.avgOptionsAvailable) : null;
      formattedLegalAd['Average Options Selected'] = form.avgOptionsSelected ? currencyFormatter.format(form.avgOptionsSelected) : null;
      formattedLegalAd['Are Lot Premiums Excluded'] = form.isLotPremiumsExcluded;
      formattedLegalAd['Is Combinable Offer'] = form.isCombinableOffer;
      formattedLegalAd['Offer Additional Limits'] = form.offerAdditionalLimits;
      formattedLegalAd['Is Offer Widely Available'] = form.isOfferWidelyAvailable;
      formattedLegalAd['Image Types Description'] = form.imageTypesDescription;
      formattedLegalAd['Contract Start Date'] = form.contractExecStartDate ? new Date(form.contractExecStartDate).toLocaleDateString() : null;
      formattedLegalAd['Contract End Date'] = form.contractExecEndDate ? new Date(form.contractExecEndDate).toLocaleDateString() : null;
      formattedLegalAd['Contract Close-By Date'] = form.contractCloseByDate ? new Date(form.contractCloseByDate).toLocaleDateString() : null;
      formattedLegalAd['Was Community Developed'] = form.wasCommDeveloped;
      formattedLegalAd['Is Community Amenities'] = form.isCommAmenities;
      formattedLegalAd['Community Product Advertised'] = form.commProdAdvertised;
      formattedLegalAd['Has HOA Dues'] = form.isHoaDues;
      formattedLegalAd['Is Age Restricted'] = form.isAgeRestricted;
      formattedLegalAd['Is Approved By PMC'] = form.isApprovedByPMC;
      formattedLegalAd['Additional Information'] = form.additionalInfo;
      formattedLegalAd['Approver Notes'] = form.approverNotes;

      // Single Options
      formattedLegalAd['Area'] = this.getNameForMatchingObj(form.areaId, this.areaData);
      formattedLegalAd['Division'] = this.getNameForMatchingObj(form.marketId, this.divisionData);
      formattedLegalAd['Brand'] = this.getNameForMatchingObj(form.brandId, this.brandData);
      formattedLegalAd['Request Type'] = this.getRequestTypeNameForMatchingObj(form.requestTypeId, this.requestTypeData);
      formattedLegalAd['Target Type'] = this.getTargetNameForMatchingObj(form.targetTypeId, this.targetTypeData);

      // Multi Options
      formattedLegalAd['Geographic Areas'] = this.getMatchingGeoAreaObjArray(this.geoAreaData, form.legalAdGeoAreas).map(e => e.name).join(", ");
      formattedLegalAd['Media Types'] = this.getMatchingTypeObjArray(this.mediaTypeData, form.legalAdMediaTypes, 'mediaTypeId').map(e => e.type).join(", ");
      formattedLegalAd['Home Types'] = this.getMatchingTypeObjArray(this.homeTypeData, form.legalAdHomeTypes, 'homeTypeId').map(e => e.type).join(", ");
      formattedLegalAd['Image Types'] = this.getMatchingTypeObjArray(this.imageTypeData, form.legalAdImageTypes, 'imageTypeId').map(e => e.type).join(", ");

      // Misc
      formattedLegalAd['Form Link'] = `${environment.appUrl}form/${form.legalAdId}`;
      formattedLegalAd['Files'] = form.legalAdFiles.map(e => e.fileName).join(", ");

      // Add
      formattedLegalAds.push(formattedLegalAd);
    });

    return formattedLegalAds;
  }

  // Save an excel file using file-saver
  private saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_Export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  private getOpts() {
    const opts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*.*'
      })
    };
    return opts;
  }
}
