import { Component } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from './../environments/environment';
import { LoadingIndicatorService } from 'src/services/loading-indicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  private loading = false;
  private sub: any;

  constructor(private adalService: AdalService, private loadingIndicatorService: LoadingIndicatorService) {
    // init requires object with clientId and tenant properties
    adalService.init(environment.azureAuthProvider);
    this.sub = loadingIndicatorService.onLoadingChanged.subscribe(isLoading => this.loading = isLoading);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }
}
