import { Pipe, PipeTransform } from '@angular/core';
import { LegalAd, FormRequestStatus } from '../models/LegalAd';
import { filter } from 'rxjs/operators';

@Pipe({
    name: 'filterSubmitted',
    pure: false
})
export class FormRequestSubmittedPipe implements PipeTransform {
    transform(items: LegalAd[], approver: boolean): any {
        if (!items || !approver)
            return items;
        return items.filter(item => item.requestStatus == FormRequestStatus.Submitted);
    }
}