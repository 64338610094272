import { LegalAd } from './LegalAd';
import { LegalAdGeoArea } from './LegalAdGeoArea';
import { LegalAdHomeType } from './LegalAdHomeType';
import { LegalAdMediaType } from './LegalAdMediaType';
import { LegalAdOfferType } from './LegalAdOfferType';
import { LegalAdImageType } from './LegalAdImageType';

// THIS IS AN EXTENSION OF THE 'LEGALAD' MODEL TO ALSO INCLUDE THE LEGAL AD COLLECTION TYPES FROM THE API
export class LegalAdReviewForm extends LegalAd {
    public legalAdGeoAreas: LegalAdGeoArea[];
    public legalAdHomeTypes: LegalAdHomeType[];
    public legalAdMediaTypes: LegalAdMediaType[];
    public legalAdOfferTypes: LegalAdOfferType[];
    public legalAdImageTypes: LegalAdImageType[];
}