import { Component, OnInit, ViewChild, Input, QueryList, ViewChildren } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { NgForm } from '@angular/forms';
import { FileUpload } from 'primeng/primeng';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { AdalService } from 'adal-angular4';
import { LegalAdService } from '../../services/legal-ad.service';
import { DataHandlerService } from 'src/services/data-handler.service';
import { MessageService } from 'primeng/api';
import { AppInsightsService } from '../../services/app-insights.service';

import { FormRequestStatus } from '../../models/LegalAd';
import { LegalAdReviewForm } from '../../models/LegalAdReviewForm';
import { Brand } from 'src/models/Brand';
import { GeographicArea } from 'src/models/GeographicArea';
import { MediaType } from 'src/models/MediaType';
import { ImageType } from 'src/models/ImageType';
import { HomeType } from 'src/models/HomeType';
import { Division } from 'src/models/Division';
import { RequestType } from 'src/models/RequestType';
import { TargetType } from 'src/models/TargetType';
import { Area } from 'src/models/Area';
import { LegalAdDisplayFile } from 'src/models/LegalAdDisplayFile';
import { IdentityService, IdentityServiceRole } from 'src/services/identity.service';
import { LoadingIndicatorService } from 'src/services/loading-indicator.service';
import { Constants } from '../Constants';

@Component({
  selector: 'app-legal-ad-form',
  templateUrl: './legal-ad-form.component.html',
  styleUrls: ['./legal-ad-form.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class LegalAdFormComponent implements OnInit {
  // Form
  @ViewChild('legalAdForm', { static: true }) private legalAdForm: NgForm;
  @Input() currentForm: LegalAdReviewForm;
  private formDisabled: boolean = false;
  private formSubmitted: boolean = false;
  private formApproved: boolean = false;
  private formNeedsChanges: boolean = false;
  private isExistingForm: boolean = false;

  private loading: boolean = false;
  private loadingDivisions: boolean = false;
  private diffApproveDays: number;

  // Subscribers
  private sub: any;
  private aSub: any;

  // PrimeNG FileAttach
  @ViewChildren("fileAttachments") private fileComponents: QueryList<FileUpload>
  @ViewChild('fileAttachments', { static: false }) private fileAttachments: FileUpload;
  private legalAdDisplayFiles: LegalAdDisplayFile[] = [];
  private filesToUpload: File[] = [];

  private disableFileUpload: boolean = false;
  private disableApproverNotes: boolean = false;

  // PrimeNG Multi-Select Dropdown List Data
  private geoAreaData: GeographicArea[];
  private mediaTypeData: MediaType[];
  private imageTypeData: ImageType[];
  private homeTypeData: HomeType[];

  // Single-Select Dropdown List Data
  private areaData: Area[];
  private divisionData: Division[];
  private brandData: Brand[];
  private requestTypeData: RequestType[];
  private targetTypeData: TargetType[];

  private multiDropdownSettings = {};
  private defaultMinDateValue: Date;

  private userId: number;

  // Roles
  private corpsys = false;
  private admin = false;
  private requestor = false;
  private approver = false;

  constructor(public adalService: AdalService, private legalAdService: LegalAdService, private appInsightsService: AppInsightsService,
    private messageService: MessageService, private route: ActivatedRoute, private router: Router, private identityService: IdentityService,
    private loadingService: LoadingIndicatorService, private dataHandlerService: DataHandlerService) {
    this.appInsightsService.logPageView('legal-ad-form.component', '/form');
    this.sub = loadingService.onLoadingChanged.subscribe(isLoading => this.loading = isLoading);
    this.defaultMinDateValue = this.getDefaultMinDateValue();
  }

  ngOnInit() {
    this.adalService.handleWindowCallback();
    this.createCurrentForm();
    if (this.adalService.userInfo.authenticated) {
      var role = this.identityService.getRoles();
      switch (role[0]) {
        case IdentityServiceRole.LegalAdApprove:
          this.approver = true;
          break;
        case IdentityServiceRole.LegalAdAdmin:
          this.admin = true;
          break;
        case IdentityServiceRole.LegalAdCorpSystems:
          this.corpsys = true;
          break;
        case IdentityServiceRole.LegalAdRequest:
          this.requestor = true;
          break;
        default:
          break;
      }
    }
  }

  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
    this.currentForm = null;
    this.messageService.clear();
    sessionStorage.clear();
  }

  ngAfterViewInit() {
    // Retrieve our PrimeNG file attachments component
    this.sub = this.fileComponents.changes.subscribe((comps: QueryList<any>) => { this.fileAttachments = comps.first; });
  }

  ngAfterContentChecked() {
    // Set that the form is disabled if 2 or more days have passed since it was originally submitted OR if the usernames don't match.
    if (this.currentForm && this.currentForm.dateSubmitted) {
      if ((this.formApproved && this.diffApproveDays >= Constants.NumberOfDaysAllowEditAfterApprove) || this.formUsersDiff()) {
        setTimeout(_ => this.disableFormInputs());
      }
    }
  }

  private createCurrentForm() {
    this.sub = this.route.params.subscribe(params => {
      // Get form id from URL and make a call for to retrieve that specific form's data if we have a form id.
      const formId = params['id'];
      if (formId && formId >= 0) {
        this.isExistingForm = true;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: "Loading form..." });
        this.sub = this.legalAdService.getFormByID(formId).subscribe((form: LegalAdReviewForm) => {
          // Set Form
          this.currentForm = form;
          // Set Form Status Props (FormApproved, FormSubmitted, etc)
          this.setFormStatusProps();
          // Population Existnig Form Data
          this.populateFormInputData();
          console.log(this.currentForm);
        }, error => {
          console.log(error.message);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Error occurred while retrieving the form." });
        }).add(() => {
          if (this.currentForm) {
            this.diffApproveDays = this.date_diff_indays(new Date(), this.currentForm.dateApproved);
          }
        });
      } // Else, just create and populate a new one...
      else {
        this.currentForm = new LegalAdReviewForm();
        this.populateFormInputData();
      }
    })
  }

  private date_diff_indays = function (date1, date2) {
    var dt1 = new Date(date1);
    var dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())) / (1000 * 60 * 60 * 24));
  }

  private formUsersDiff(): boolean {
    return this.currentForm.requestorUserId != this.adalService.userInfo.userName;
  }

  private getCurrentUserFullName(): string {
    if (this.authenticated && this.adalService.userInfo)
      return `${this.adalService.userInfo.profile.given_name} ${this.adalService.userInfo.profile.family_name}`;
  }

  private disableFormInputs() {
    // Set form disabled variable.
    this.formDisabled = true;
    // FileUpload and ApproverNotes disable/enable toggle depending on if form is approved.
    if (this.formApproved) {
      if (!this.formNeedsChanges) {
        //this.disableFileUpload = true; // Commented out for now as to never disable File Upload, even if it's been approved. Pending future discussion?
      }
      if (this.legalAdForm.controls['approverNotes']) {
        this.disableApproverNotes = true;
      }
    }
  }

  private populateFormInputData() {
    // Requestor
    if (!this.currentForm.requestor && this.adalService.userInfo.authenticated) {
      this.currentForm.requestor = this.getCurrentUserFullName();
      this.currentForm.requestorUserId = this.adalService.userInfo.userName;
    }
    // Date inputs
    this.loadDateControls();
    // Dropdown inputs
    this.loadSingleSelectDropdowns();
    this.loadMultiSelectDropdowns();
    // Form Properties
    this.currentForm.formRequestType = this.currentForm.formRequestType ? this.currentForm.formRequestType : "Legal Ad Review Form";
    this.currentForm.requestStatus = this.currentForm.requestStatus ? this.currentForm.requestStatus : FormRequestStatus.Created;
    // Files
    this.currentForm.legalAdFiles = this.currentForm.legalAdFiles ? this.currentForm.legalAdFiles : [];
    this.getAdHocFiles();
  }

  private loadDateControls() {
    // WORKAROUND? For the issue of date strings coming back from the API and being incorrectly parsed, they need to be re-parsed here.
    this.currentForm.dateSubmitted = this.currentForm.dateSubmitted ? new Date(Date.parse(this.currentForm.dateSubmitted.toString())) : this.currentForm.dateSubmitted;
    this.currentForm.dateApproved = this.currentForm.dateApproved ? new Date(Date.parse(this.currentForm.dateApproved.toString())) : this.currentForm.dateApproved;
    this.currentForm.dateFeedbackNeeded = this.currentForm.dateFeedbackNeeded ? new Date(Date.parse(this.currentForm.dateFeedbackNeeded.toString())) : this.currentForm.dateFeedbackNeeded;
    this.currentForm.contractCloseByDate = this.currentForm.contractCloseByDate ? new Date(Date.parse(this.currentForm.contractCloseByDate.toString())) : this.currentForm.contractCloseByDate;
    this.currentForm.contractExecStartDate = this.currentForm.contractExecStartDate ? new Date(Date.parse(this.currentForm.contractExecStartDate.toString())) : this.currentForm.contractExecStartDate;
    this.currentForm.contractExecEndDate = this.currentForm.contractExecEndDate ? new Date(Date.parse(this.currentForm.contractExecEndDate.toString())) : this.currentForm.contractExecEndDate;
    if (this.currentForm.dateFeedbackNeeded)
      this.currentForm.dateFeedbackNeeded.setHours(12, 5, 0);
  }

  private setFormStatusProps() {
    setTimeout(_ => this.formApproved = this.currentForm.requestStatus == FormRequestStatus.Approved ||
      this.currentForm.requestStatus == FormRequestStatus.ApprovedNoChanges ||
      this.currentForm.requestStatus == FormRequestStatus.ApprovedWithChanges);
    setTimeout(_ => this.formSubmitted = this.currentForm.requestStatus == FormRequestStatus.Submitted);
    setTimeout(_ => this.formNeedsChanges = this.currentForm.requestStatus == FormRequestStatus.ApprovedWithChanges);
  }

  private loadSingleSelectDropdowns() {
    this.sub = forkJoin(
      this.legalAdService.getFinancialAreas(),
      this.legalAdService.getFinancialBrands(),
      this.legalAdService.getRequestTypes(),
      this.legalAdService.getTargetTypes()
    )
      .subscribe(([res1, res2, res3, res4]: [any, any, any, any]) => {
        this.areaData = res1;
        this.brandData = res2;
        this.requestTypeData = res3;
        this.targetTypeData = res4;
      },
        error => {
          console.log(error.message);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving the single-select data..." });
        },
        () => {
          this.setDefaultSingleSelectData();
        });
  }

  private loadMultiSelectDropdowns() {
    // SETTINGS
    this.multiDropdownSettings = {
      geoAreaSettings: { singleSelection: false, idField: 'geoAreaId', textField: 'name', itemsShowLimit: 51, allowSearchFilter: true, limitSelection: 51 },
      mediaTypeSettings: { singleSelection: false, idField: 'mediaTypeId', textField: 'type', itemsShowLimit: 51, allowSearchFilter: true, limitSelection: 51 },
      homeTypeSettings: { singleSelection: false, idField: 'homeTypeId', textField: 'type', itemsShowLimit: 51, allowSearchFilter: true, limitSelection: 51 },
      imageTypeSettings: { singleSelection: false, idField: 'imageTypeId', textField: 'type', itemsShowLimit: 5, allowSearchFilter: false, limitSelection: 5 }
    };

    // Get Geograpic Areas from static data. Move into it's own table in the DB in the future?
    if (!this.geoAreaData || this.geoAreaData.length <= 0)
      this.geoAreaData = this.legalAdService.getGeographicAreas();

    this.sub = forkJoin(
      this.legalAdService.getMediaTypes(),
      this.legalAdService.getHomeTypes(),
      this.legalAdService.getImageTypes()
    )
      .subscribe(([res1, res2, res3]: [any, any, any]) => {
        this.mediaTypeData = res1;
        this.homeTypeData = res2;
        this.imageTypeData = res3;
      },
        error => {
          console.log(error.message);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving the multi-select data..." });
        },
        () => {
          this.setDefaultMultiSelectData();
        });
  }

  // Set the proper dropdown index if we found a matching element in the retrieved data, else set to -1 (default).
  private setDefaultSingleSelectData() {
    if (this.currentForm) {
      // Area
      this.currentForm.areaId = this.dataHandlerService.getIdForMatchingObj(this.currentForm.areaId, this.areaData);
      // Get Division Data from API if an Area ID exists, else set Division index to -1 (default);
      if (this.currentForm.areaId >= 0 && this.areaData && this.areaData.length > 0)
        this.getDivisionData(this.currentForm.areaId);
      else
        this.currentForm.marketId = -1;
      // Brand
      this.currentForm.brandId = this.dataHandlerService.getIdForMatchingObj(this.currentForm.brandId, this.brandData);
      // Request Type
      this.currentForm.requestTypeId = this.dataHandlerService.getRequestTypeIdForMatchingObj(this.currentForm.requestTypeId, this.requestTypeData);
      // Target Type
      this.currentForm.targetTypeId = this.dataHandlerService.getTargetIdForMatchingObj(this.currentForm.targetTypeId, this.targetTypeData);
    }
  }

  private clearEmptyDefaultSingleSelectData() {
    if (this.currentForm) {
      // Area
      this.currentForm.areaId = this.currentForm.areaId >= 0 ? this.currentForm.areaId : null;
      // Division
      this.currentForm.marketId = this.currentForm.marketId >= 0 ? this.currentForm.marketId : null;
      // Brand
      this.currentForm.brandId = this.currentForm.brandId >= 0 ? this.currentForm.brandId : null;
      // Request Type
      this.currentForm.requestTypeId = this.currentForm.requestTypeId >= 0 ? this.currentForm.requestTypeId : null;
      // Target Type
      this.currentForm.targetTypeId = this.currentForm.targetTypeId >= 0 ? this.currentForm.targetTypeId : null;
    }
  }

  private setDefaultMultiSelectData() {
    if (this.currentForm) {
      // Geo Areas
      this.currentForm.geographicAreas = this.dataHandlerService.getMatchingGeoAreaObjArray(this.geoAreaData, this.currentForm.legalAdGeoAreas);
      // Media Types
      this.currentForm.mediaTypes = this.dataHandlerService.getMatchingTypeObjArray(this.mediaTypeData, this.currentForm.legalAdMediaTypes, 'mediaTypeId');
      // Home Types
      this.currentForm.homeTypes = this.dataHandlerService.getMatchingTypeObjArray(this.homeTypeData, this.currentForm.legalAdHomeTypes, 'homeTypeId');
      // Image Types
      this.currentForm.imageTypes = this.dataHandlerService.getMatchingTypeObjArray(this.imageTypeData, this.currentForm.legalAdImageTypes, 'imageTypeId');
    }
  }

  private getDivisionData(value) {
    this.loadingDivisions = true;
    this.sub = this.legalAdService.getFinancialDivisions(value).subscribe((divisions: any) => {
      if (!!divisions && divisions.length > 0) {
        this.divisionData = divisions;
        // Set the proper dropdown index if we found a matching element in the retrieved data, else set to -1 (default).
        this.currentForm.marketId = this.dataHandlerService.getIdForMatchingObj(this.currentForm.marketId, this.divisionData);
      }
      else { }
      this.loadingDivisions = false;
    }, error => {
      console.log(error.message);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving the divisions..." });
      this.loadingDivisions = false;
    });
  }

  private async onSubmit() {
    // Valid
    if (this.legalAdForm.submitted && this.legalAdForm.valid) {
      // Files Attached
      if ((!this.currentForm.legalAdFiles || this.currentForm.legalAdFiles.length <= 0) && this.filesToUpload.length <= 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please attach the ad/collateral before submitting the form." });
        this.scrollFileAttachmentsIntoView();
        return;
      }
      // Authed
      if (this.adalService.userInfo.authenticated) {
        if (this.formApproved && this.formNeedsChanges) {
          this.currentForm.requestStatus = FormRequestStatus.ApprovedWithChanges;
        }
        else {
          this.currentForm.requestStatus = FormRequestStatus.Submitted;
          this.currentForm.dateSubmitted = this.getDefaultMinDateValue();
        }
        this.aSub = this.createOrUpdateLegalAdForm();
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "One or more required fields need input." });
    }
  }

  private async onSave() {
    this.clearEmptyDefaultSingleSelectData()
    if (this.currentForm.requestTitle) {
      if (this.adalService.userInfo.authenticated) {
        this.aSub = this.createOrUpdateLegalAdForm();
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "A request form title is required. Please enter one." });
    }
  }

  private async onApprove() {
    if (this.formSubmitted && !this.formApproved) {
      if (this.adalService.userInfo.authenticated && this.approver) {
        if (this.formNeedsChanges)
          this.currentForm.requestStatus = FormRequestStatus.ApprovedWithChanges;
        else
          this.currentForm.requestStatus = FormRequestStatus.ApprovedNoChanges;
        this.currentForm.dateApproved = this.getDefaultMinDateValue();
        this.aSub = this.createOrUpdateLegalAdForm();
      }
    }
    else {
      this.formNeedsChanges = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error while approving the form. Please try again later." });
    }
  }

  private async createOrUpdateLegalAdForm() {
    // Create or Update Form
    if (this.isExistingForm) {
      await this.legalAdService.updateLegalAdForm(this.currentForm).then((guid: any) => {
        // Update Files
        this.updateAdhocFiles(guid);
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "An error occurred while updating the Legal Ad Review Form. Please try again later." });
        console.log(err);
      });
    }
    else {
      await this.legalAdService.createLegalAdForm(this.currentForm).then((guid: any) => {
        this.updateAdhocFiles(guid);
      }).catch(err => {
        //this.currentForm.requestStatus = FormRequestStatus.Created;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "An error occurred while adding the new Legal Ad Review Form. Please try again later." });
        console.log(err);
      });
    }
  }

  private async updateAdhocFiles(guid: string) {
    // Add files collected for upload.
    if (this.filesToUpload && this.filesToUpload.length > 0) {
      for (let i = 0; i < this.filesToUpload.length; i++) {
        this.aSub = this.legalAdService.uploadAdhocFiles(this.filesToUpload[i], guid)
          .then((res) => {
            if ((i + 1) == this.filesToUpload.length) {
              this.router.navigate(['/home']);
            }
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "An error occurred during the upload of the file attachments. Please try again later." });
            console.log(err);
          });
      }
    }
    else {
      this.router.navigate(['/home']);
    }
  }

  private getAdHocFiles() {
    // Get AdHoc file URIs that match with the GUID, match them with our LegalAdFiles by FileName, then add them to the form.
    if (this.currentForm && this.currentForm.legalAdFiles.length > 0) {
      // Retrieve a list of unique GUIDs off of the returned LegalAdFiles from the API.
      const guids = [...new Set(this.currentForm.legalAdFiles.map(legalAdFile => legalAdFile.fileGuid))];
      if (guids && guids.length > 0) {
        guids.forEach(guid => {
          // Get the one or more corresponding file URIs back from Azure that match each GUID.
          this.sub = this.legalAdService.getAdhocFiles(guid).subscribe((uris: any) => {
            if (!!uris && uris.length > 0) {
              // Match each URI to the proper LegalAdFile and create a LegalAdDisplayFile to show to the users on the form.
              uris.forEach(uri => {
                this.currentForm.legalAdFiles.forEach(legalAdFile => {
                  const blobFileName = uri.substring(uri.indexOf(legalAdFile.fileGuid + '^')).split('?')[0].split('^');
                  const matchedGuid = blobFileName[0];
                  const matchedFileName = blobFileName.pop().split('?')[0];
                  if (matchedGuid === legalAdFile.fileGuid && matchedFileName === legalAdFile.fileName) {
                    this.legalAdDisplayFiles.push({
                      uploadDate: legalAdFile.uploadDate,
                      authorName: legalAdFile.authorName,
                      fileName: legalAdFile.fileName,
                      fileGuid: legalAdFile.fileGuid,
                      file: null,
                      fileURI: uri,
                    })
                  }
                });
              });
            }
            else { }
          }, error => {
            console.log(error.message);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "There was an error retrieving the filez..." });
          });
        });
      }
    }
  }

  private onFileAttach() {
    // Attach the files the user wants to attach to the review then clear the FileUpload control
    if (this.fileAttachments.files && this.fileAttachments.files.length > 0) {
      this.fileAttachments.files.forEach(fileToUpload => {
        // Don't let user attach in the same session a file when they have one already named the same.
        if (this.currentForm.legalAdFiles.some(f => f.fileName === fileToUpload.name && f.fileGuid == null)) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Duplicate file name error." });
          this.fileAttachments.files.pop();
          return false;
        }
        else {
          var authorName = this.getCurrentUserFullName();
          var uploadDate = new Date();
          // Add to LegaAdFiles (what gets sent to the API on the LegalAd model)
          this.currentForm.legalAdFiles.push({ fileName: fileToUpload.name, authorName: authorName, uploadDate: uploadDate, fileGuid: null });
          // Add to files to upload
          this.filesToUpload.push(fileToUpload);
          // Add to Legal Ad Display files (what's shown to the user)
          var ladfile = new LegalAdDisplayFile();
          ladfile.uploadDate = uploadDate;
          ladfile.authorName = authorName;
          ladfile.fileName = fileToUpload.name;
          ladfile.file = fileToUpload;
          this.legalAdDisplayFiles.push(ladfile);
        }
      });
    }
    this.fileAttachments.files = [];
  }

  private removeFile(ladFile: LegalAdDisplayFile) {
    // Only remove the file if it hasn't been uploaded to Azure yet (i.e. they haven't submitted the form yet)
    if (!ladFile.fileURI) {
      // Remove from Legal Ad Display files (what's shown to the user)
      var index = this.legalAdDisplayFiles.indexOf(ladFile);
      if (index > -1)
        this.legalAdDisplayFiles.splice(index, 1);
      // Remove from files to upload.
      index = this.filesToUpload.indexOf(ladFile.file);
      if (index > -1)
        this.filesToUpload.splice(index, 1);
      // Remove from LegaAdFiles (what gets sent to the API on the LegalAd model)
      var laFile = this.currentForm.legalAdFiles.find(f => f.fileName === ladFile.fileName);
      index = this.currentForm.legalAdFiles.indexOf(laFile);
      if (index > -1)
        this.currentForm.legalAdFiles.splice(index, 1);
    }
  }

  private onPrint() {
    console.log('print');
  }

  private clearForm() {
    this.currentForm = new LegalAdReviewForm();
    this.fileAttachments.files = [];
    this.formSubmitted = false;
  }

  private getDefaultMinDateValue() {
    return new Date(new Date().toISOString().slice(0, 19).replace('T', ' '));
  };

  private scrollFileAttachmentsIntoView() {
    this.fileAttachments['el'].nativeElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
}
