import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AdalService } from 'adal-angular4';
import { Router, ActivatedRoute } from '@angular/router';
import { IdentityService, IdentityServiceRole } from 'src/services/identity.service';
//import { UserRole } from 'src/services/identity.service';

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.css']
})
export class NavTopComponent implements OnInit {

  private formId: number;
  private routeSub: any;

  // Roles
  private corpsys = false;
  private admin = false;
  private requestor = false;
  private approver = false;

  constructor(private router: Router, private _location: Location, public adalService: AdalService,
              private activatedRoute: ActivatedRoute, private identityService: IdentityService) { }

  ngOnInit() {
    this.adalService.handleWindowCallback();
    if (this.adalService.userInfo.authenticated){
      const role = this.identityService.getRoles();
      switch (role[0]) {
        case IdentityServiceRole.LegalAdApprove:
          this.approver = true;
          break;
        case IdentityServiceRole.LegalAdAdmin:
          this.admin = true;
          break;
        case IdentityServiceRole.LegalAdCorpSystems:
          this.corpsys = true;
          break;
        case IdentityServiceRole.LegalAdRequest:
          this.requestor = true;
          break;          
        default:
          break;
      } 
    }
  }

  ngOnDestroy() {
    if (this.routeSub)
      this.routeSub.unsubscribe();
  }

  login(): void {
    this.adalService.login();
  }

  logout(): void {
    this.adalService.logOut();
      if (this.router.url==="/form")
        this.router.navigate(["/home"]);
  }

  get authenticated(): boolean {
      return this.adalService.userInfo.authenticated;
  }

  get existingForm(): boolean {
    if (this.activatedRoute.firstChild) {
      this.formId = this.activatedRoute.firstChild.snapshot.params['id']
      return (this.formId && this.formId >= 0);
    }
    return false;
  }
}
