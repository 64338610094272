import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdalService } from 'adal-angular4';
import { IdentityService, IdentityServiceRole } from 'src/services/identity.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class LegalAdGuard implements CanActivate {

  constructor(private adalService: AdalService, private router: Router, 
              private identityService: IdentityService, private messageService: MessageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.adalService.userInfo.authenticated) {
      var role = this.identityService.getRoles();
      if (role && role[0] === IdentityServiceRole.LegalAdApprove) {
        // Is the current path equivalent to /form/{id}/ for the Approval User?
        const formPathMatches = state.url.match(/\/form\/([0-9]+)(?=[^\/]*$)/i);
        if (!formPathMatches) {
          this.returnUnauthorized();
        }
      }
      return true;
    } 
    else {
      this.returnUnauthorized();
    }
  }

  private returnUnauthorized() {
    this.messageService.add({severity: 'error', summary: 'Error', detail: "Sorry, you don't have permission to view this page."});
    this.router.navigate(['/']);
    return false;
  }
}
