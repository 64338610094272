import { Directive, forwardRef } from '@angular/core'; 
import {NG_VALIDATORS, FormControl} from '@angular/forms';

@Directive({
    selector: '[optionNotNeg][ngModel],[optionNotNeg][formControl]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => OptionNotNegDirective), multi: true }
      ]
})

export class OptionNotNegDirective {
    validator: Function;

    constructor() {
      this.validator = optionNotNegValidator();
    }
  
    validate(c: FormControl) {
      return this.validator(c);
    }
}

function optionNotNegValidator() {
    return (c: FormControl) => {    
        return (c.value && c.value > 0) ? null : {
            optionNotNeg: {
                valid: false,
                invalid : true
            }
        };
    };
}